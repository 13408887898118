import { Container, Content, ContentSecond } from "./styles";

export function Home() {
    return (
        <Container>
            <Content>
                <h1>GERADOR DE PLACA PIX</h1>

                <ContentSecond>
                    <div>
                        <h2>Personalize sua placa</h2>
                        <br />
                        <h3>Utilize a ferramenta abaixo para gerar a placa com o seu estilo!</h3>
                    </div>

                    <img src="favicon.png" alt="Logo" />
                </ContentSecond>

                <img src="arrowdown.gif" alt="Rolar para baixo" width="50rem" title="Rolar para baixo" />
            </Content>
        </Container>
    )
}