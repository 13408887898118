import { GlobalStyle } from "./styles/global";
import { Home } from "./components/Home";
import { Instructions } from "./components/Instructions";
import { Generator } from "./components/Generator";
import { Footer } from "./components/Footer";
import { Contact } from "./components/Contact";
import { Analytics } from '@vercel/analytics/react';


export function App() {
  return (
    <>
      <Home />
      <Instructions />
      <Generator />
      <Contact />
      <Footer />
      <GlobalStyle />
      <Analytics />
    </>
  );
}

