import styled from "styled-components";


export const Container = styled.section`
 background: linear-gradient(180deg, #000000, #42bdae);
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;

    max-width: 60%;
    margin: 0 auto;

    align-items: flex-start;
    justify-content: center;

    text-align: justify;

    h2 {
        align-self: center;
        margin: 2rem 0rem;
        text-align: center;
    }

    h4 {
        margin-bottom: 1rem;
    }

    p {
        margin-bottom: 2rem;
        margin-left: 1rem;
    }

    @media screen and (max-width: 980px) {
        max-width: 80%;
    }
`

export const Tutorial = styled.div`
    h2, h4, p {
        color: rgb(20,20,20);
    }
`

export const Assinatura = styled.p`
    font-family: 'Reenie Beanie', cursive;
    font-size: 2rem;
`

export const CourierPrime = styled.p`
    font-family: 'Courier Prime', monospace;
`