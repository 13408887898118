import styled from "styled-components";


export const Container = styled.footer`
    background-color: rgba(20, 20, 20, 0.9);   
    
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    margin: 0 auto;

    font-size: 0.8rem;
    padding: 0.5rem;

    text-align: center;

`