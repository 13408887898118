import styled from "styled-components";

export const Container = styled.section`
    background: url("fundo1.jpg") no-repeat top center/cover;
`

export const Content = styled.div`
    max-width: 90%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    
    padding: 3rem 0rem; 
    gap: 0.5rem;

    h1, h2, h3 {
        text-align: center;
    }

   
`

export const ContainerCards = styled.div`

    width: 100%;
    
    display: flex;
    justify-content: space-around;

    gap: 1rem;

    @media screen and (max-width: 1024px) {
        flex-direction: column-reverse;
        align-items: center;

    }

`

export const CardForm = styled.div`
    background-color: rgb(40,40,40);  

    display: flex;
    flex-direction: column;

    width: 28%; 

    border-radius: 1rem;
    padding: 2rem; 

    max-height: 82vh;
    overflow-y: auto;

    gap: 0.5rem;

    p {
        font-size: 12px;
    }



    button {
        padding: 0.5rem;
        margin-bottom: 0.5rem;
        /* background-color: transparent ; */
        border-radius: 1rem;
        outline: none;
        font-size: 1rem;
        display: flex;
        align-items: center;
        gap: 1rem;

        background: linear-gradient(180deg, #e81cff, #40c9ff);
    }

    @media screen and (max-width: 1024px) {
        width: 100%;
        max-height: 100%;
     
    }
    

`

export const InputContainer = styled.div`
    input, select {
        width: 100%;
        padding: 0.2rem;
        margin-bottom: 1rem;
        background-color: transparent;
        border: 2px solid transparent; 
        border-image: linear-gradient(180deg, transparent 0%, #e81cff, #40c9ff); 
        border-image-slice: 1; 
        border-image-width: 1px; 
        border-image-repeat: stretch;
        outline: none;
    }

    select option {
        background-color:  rgb(40,40,40); 
    }

    input:focus {
        background-color: rgba(55, 55, 55, 0.4);
    }
`

export const InputColorContainer = styled.div`
    input {
        width: 100%;
        margin-bottom: 0.5rem;
        background-color: transparent;
        border: 2px solid transparent; 
        outline: none; 
    }
`

export const CardPreview = styled.div`
    background-color: var(--gray-900);  

    display: flex;
    flex-direction: column;

    width: 68%;

    border-radius: 1rem;
    padding: 2rem 0rem; 

    @media screen and (max-width: 1024px) {
        width: 100%; 
    }
`

export const Preview = styled.div<{
    backgroundColor: string,
    titleColor: string,
    fontColor: string,
    borderWidth: number,
    borderType: string,
    borderColor: string,
    backgroundImage?: string,
}>`

    /* Estilização fixa */
    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 1rem;
    gap: 1rem;

    padding: 3rem 0rem;


    width: 60%;
    margin: 0 auto;

    img.img-qr-code {
        width: 35%;
    }


    @media screen and (max-width: 1440px) {
        width: 80%; 
    }

    @media screen and (max-width: 768px) {
        width: 90%; 
        h3 {
            font-size: 1rem;
        }

        img.img-qr-code {
            width: 45%;
        }
    }

    /* Estilização variável */
    background: ${(props) => props.backgroundImage ? `url(${props.backgroundImage}) no-repeat top center/cover` : props.backgroundColor};


    border: ${(props) => props.borderWidth || 0}px ${(props) => props.borderType} ${(props) => props.borderColor};

   

    h2, h3 {
        color: ${(props) => props.titleColor};
    }

    p {
        color: ${(props) => props.fontColor};
    }

`


export const Tag = styled.div<{ tagColor: string }>`
    background-color: ${(props) => props.tagColor};
    width: 60%;
    padding: 0.5rem 2rem;
    border-radius: 1rem;
    
    h3 {
        text-align: start;
    }

    @media screen and (max-width: 768px) {
        width: 70%; 
        h3 {
            font-size: 1rem;
        }


        img.img-qr-code {
        width: 45%;
    }
    }

    
`

export const PixCopiaCola = styled.div`
    background-color: #ffffff;
    padding: 0.5rem 2rem;
    border-radius: 1rem;
    margin: 1rem;

    width: 60%;

    p {
        word-break: break-all;
    }
`
