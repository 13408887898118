import { Container, Content } from "./styles";

export function Footer() {
    return (
        <Container>
            <Content>
                <p>Desenvolvido por Laura La-Rocca - 2023 - Todos os direitos reservados</p>
            </Content>
        </Container>
    )
}