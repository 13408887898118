import styled from "styled-components";


export const Container = styled.section`    
  background: linear-gradient(180deg, #000000, #42bdae); 
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  max-width: 90%;
  margin: 0 auto;

  padding: 1rem;

  img {
    width: 5rem;
    max-width: 100%;
  }

  @media screen and (max-width: 580px) {
    img {
      width: 3rem;
    }

  }
`

export const CardContact = styled.div`
  background-color: var(--bg-header);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  text-align: center;
  gap: 1rem;

  margin: 1rem;
  padding: 1rem;
`
export const SocialMedia = styled.div`
  background-color: rgba(0, 0, 0, 0.2);

  display: flex;
  font-size: 3rem;
  align-items: center;

  gap: 2rem;

  border-radius: 2rem;
  padding: 1rem 2rem;

  a  {
    display: flex;
  }

  @media screen and (max-width: 580px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
   
`;
