import styled from "styled-components";


export const Container = styled.section`
    background: linear-gradient(360deg, #000000, #42bdae);

    height: 100vh;

    h1 {
        font-size: 2.5rem;
    }
    h2 {
        font-size: 2rem;
    }

`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    max-width: 80vw;
    
    height: 100vh;

    margin: 0 auto;
    padding: 4rem 2rem;   

`



export const ContentSecond = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex: 1;
    margin: 4rem;
    
    width: 100%;

    img {
        width: 30%;
        max-width: 100%;
    }


    @media screen and (max-width: 980px) {
        flex-wrap: wrap;
        flex-direction: column-reverse;   

        img {
            width: 250px;  
        }
    }

    @media screen and (max-width: 480px) {
        img {
            width: 150px;  
        }

        
    }
`