import React, {
    ChangeEvent,
    useEffect,
    useRef,
    useState
} from "react";
import {
    CardForm,
    CardPreview,
    Container,
    ContainerCards,
    Content,
    InputColorContainer,
    InputContainer,
    PixCopiaCola,
    Preview,
    // PreviewTheme2,
    // PreviewTheme3,
    Tag
} from "./styles";
import { QrCodePix } from "qrcode-pix";
import html2canvas from "html2canvas";


export function Generator() {

    const [nome, setNome] = useState<string>("");
    const [tipoChave, setTipoChave] = useState<string>("");
    const [chave, setChave] = useState<string>("");
    const [banco, setBanco] = useState<string>("");
    const [cidade, setCidade] = useState<string>("");


    const [colorBackground, setColorBackground] = useState<string>("#ffffff");
    const [imageBackground, setImageBackground] = useState<string | undefined>("");
    const [colorTitle, setColorTitle] = useState<string>("#000000");
    const [colorFont, setColorFont] = useState<string>("#000000");
    const [colorTag, setColorTag] = useState<string>("#e2e2e2");

    const [theme, setTheme] = useState<string>("");

    const [borderWidth, setBorderWidth] = useState<number>(0);
    const [borderType, setBorderType] = useState<string>("solid");
    const [borderColor, setBorderColor] = useState<string>("#000000");


    const [logoUrl, setLogoUrl] = useState<string>("");
    const [logoWidth, setLogoWidth] = useState<number>(350);

    function handleLogoUpload(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files) {
            const file = event.target.files[0];

            if (file) {
                const reader = new FileReader();

                reader.onload = (e) => {
                    if (e.target) {
                        const url = e.target.result;
                        if (typeof url === "string") {
                            setLogoUrl(url);
                        }
                    }

                };

                reader.readAsDataURL(file);
            }
        }

    }

    function handleImgUpload(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files) {
            const file = event.target.files[0];

            if (file) {
                const reader = new FileReader();

                reader.onload = (e) => {
                    if (e.target) {
                        const url = e.target.result;
                        if (typeof url === "string") {
                            setImageBackground(() => url);
                        }
                    }

                };

                reader.readAsDataURL(file);
            }
        }

    }

    const handleNomeChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setNome(event.target.value);
    };

    const handleTipoChaveChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setTipoChave(event.target.value);
        setChave("");
    };

    const handleChaveChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const rawValue = event.target.value;
        if (tipoChave === "CPF" || tipoChave === "CNPJ" || tipoChave === "Celular") {
            setChave(() => formatChave(rawValue));
        } else if (tipoChave === "E-mail") {
            setChave(() => rawValue);
        }
    };

    const handleBancoChange = (event: ChangeEvent<HTMLInputElement>) => {
        setBanco(event.target.value);
    };

    const handleCidadeChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setCidade(event.target.value);
    };

    const handleThemeChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setTheme(event.target.value);
    };

    const handleColorBackgroundChange = (event: ChangeEvent<HTMLInputElement>) => {
        setColorBackground(() => event.target.value);
        if (imageBackground) {
            setImageBackground(() => undefined);
            const fileInput = document.getElementById('fileBackground') as HTMLInputElement;
            if (fileInput) {
                fileInput.value = "";
            }
        }
    };

    const handleColorTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setColorTitle(() => event.target.value);
    };

    const handleColorFontChange = (event: ChangeEvent<HTMLInputElement>) => {
        setColorFont(() => event.target.value);
    };

    const handleColorTagChange = (event: ChangeEvent<HTMLInputElement>) => {
        setColorTag(() => event.target.value);
    };

    const handleBorderWidthChange = (event: ChangeEvent<HTMLInputElement>) => {
        setBorderWidth(() => Number(event.target.value));
    };

    const handleBorderTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setBorderType(() => event.target.value);
    };

    const handleBorderColorChange = (event: ChangeEvent<HTMLInputElement>) => {
        setBorderColor(() => event.target.value);
    };

    const handleLogoWidthChange = (event: ChangeEvent<HTMLInputElement>) => {
        setLogoWidth(() => Number(event.target.value));
    };

    const [qrCode, setQrCode] = useState<string>('');
    const [rawPix, setRawPix] = useState<string>('');

    useEffect(() => {
        async function generateDynamicPix() {
            let keyPix = "";
            switch (tipoChave) {
                case "CPF":
                    keyPix = chave.replace(/[^\d]+/g, '');
                    break;
                case "CNPJ":
                    keyPix = chave.replace(/[^\d]+/g, '');
                    break;
                case "Celular":
                    keyPix = "+55" + chave.replace(/[^\d]+/g, '');
                    break;
                case "E-mail":
                    keyPix = chave;
                    break;
                default:
                    break;
            }

            const qrCodePix = QrCodePix({
                version: '01',
                key: keyPix,
                name: nome,
                city: cidade,
            })

            const rawPixStr = qrCodePix.payload()
            const qrCodeBase64 = await qrCodePix.base64()

            setRawPix(rawPixStr)
            setQrCode(qrCodeBase64)
        }

        generateDynamicPix();

    }, [nome, chave, cidade, tipoChave])

    const divRef = useRef(null);

    const handleDownload = () => {
        if (divRef.current) {
            html2canvas(divRef.current).then(function (canvas) {
                const imagem = canvas.toDataURL("image/png");
                const link = document.createElement("a");

                link.href = imagem;
                link.download = "placa_pix.png";
                link.click();
            });
        }
    };

    const handleCheckForm = (event: React.MouseEvent<HTMLButtonElement>) => {
        const form = document.getElementById('formPlaca') as HTMLFormElement | null;

        if (form && form.checkValidity()) {
            event.preventDefault();
            handleDownload();
        }

    }

    const handleRemoverLogo = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setLogoUrl("");
        const fileInput = document.getElementById('fileLogo') as HTMLInputElement;
        if (fileInput) {
            fileInput.value = "";
        }
    }

    const formatChave = (value: string) => {
        // Remove caracteres não numéricos
        const numericValue = value.replace(/\D/g, '');
        let formattedValue = ""
        if (tipoChave === "CPF") {
            // Aplica a formatação (123.456.789-99)
            formattedValue = numericValue.replace(
                /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
                '$1.$2.$3-$4'
            );
        } else if (tipoChave === "CNPJ") {
            // Aplica a formatação (12.345.678/0001-99)
            formattedValue = numericValue.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
                '$1.$2.$3/$4-$5'
            );
        } else if (tipoChave === "Celular") {
            // Aplica a formatação (+55 9 99999999)
            formattedValue = numericValue.replace(
                /^(\d{2})(\d{1})(\d{8})$/,
                '$1 $2 $3'
            );
        }
        return formattedValue;
    };

    const renderCampoChavePix = () => {
        if (tipoChave === "CPF") {
            return (
                <input
                    type="text"
                    placeholder="Insira apenas números"
                    maxLength={14}
                    value={chave}
                    minLength={14}
                    pattern="^\d{3}\.\d{3}\.\d{3}\-\d{2}$"
                    id="chavepix"
                    onChange={handleChaveChange}
                    required
                />
            );
        } else if (tipoChave === "CNPJ") {
            return (
                <input
                    type="text"
                    placeholder="Insira apenas números"
                    value={chave}
                    maxLength={18}
                    minLength={18}
                    pattern="^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$"
                    id="chavepix"
                    onChange={handleChaveChange}
                    required
                />
            );
        } else if (tipoChave === "E-mail") {
            return (
                <input
                    type="email"
                    placeholder="Digite seu e-mail"
                    value={chave}
                    id="chavepix"
                    onChange={handleChaveChange}
                    required
                />
            );
        } else if (tipoChave === "Celular") {
            return (
                <input
                    type="text"
                    placeholder="Insira apenas o DDD e o número"
                    value={chave}
                    id="chavepix"
                    onChange={handleChaveChange}
                    required
                />
            );
        }
    };

    return (
        <Container>
            <Content>
                <img src="favicon.png" alt="Logo" width="50rem" />
                <h1>Gerador de Placa Pix</h1>
                <h3>Preencha o formulário e desfrute dessa ferramenta poderosa</h3>
                <br />
                <ContainerCards>
                    <CardPreview>
                        <h2>Pré-visualização</h2>
                        <br />

                        {
                            theme !== "" &&

                            <Preview
                                backgroundColor={colorBackground}
                                titleColor={colorTitle}
                                fontColor={colorFont}
                                borderWidth={borderWidth}
                                borderType={borderType}
                                borderColor={borderColor}
                                backgroundImage={imageBackground}
                                ref={divRef}
                            >
                                <img src="favicon.png" alt="Logo" width="50rem" />

                                {
                                    theme === "Theme2"
                                        ? <h2>PAGUE COM PIX COPIA E COLA</h2>
                                        : <h2>PAGUE COM PIX</h2>
                                }
                                {
                                    chave !== "" && theme !== "" &&
                                    <>
                                        {
                                            (theme === "Theme1" || theme === "Theme4") &&
                                            <img className="img-qr-code" src={qrCode} alt={'QR Code PIX'} title="Qr Code Pix" />

                                        }
                                        {
                                            theme === "Theme2" &&
                                            <PixCopiaCola>
                                                <p>
                                                    {rawPix}
                                                </p>
                                            </PixCopiaCola>
                                        }
                                        {
                                            (theme === "Theme3" && logoUrl !== "") &&
                                            <img src={logoUrl} alt="Logo" width={logoWidth + "px"} />
                                        }


                                    </>
                                }

                                {
                                    (theme !== "Theme4") &&

                                    <Tag tagColor={colorTag}>
                                        <h3>Nome</h3>
                                        <p>{nome}</p>
                                    </Tag>




                                }

                                <Tag tagColor={colorTag}>
                                    <h3>Tipo de Chave</h3>
                                    <p>{tipoChave}</p>
                                </Tag>

                                <Tag tagColor={colorTag}>
                                    <h3>Chave</h3>
                                    <p>{chave}</p>
                                </Tag>
                                {
                                    (theme !== "Theme4") &&

                                    <Tag tagColor={colorTag}>
                                        <h3>Banco</h3>
                                        <p>{banco}</p>
                                    </Tag>




                                }

                                {
                                    (theme === "Theme4" && logoUrl !== "") &&
                                    <img src={logoUrl} alt="Logo" width={logoWidth + "px"} />
                                }

                            </Preview>

                        }

                    </CardPreview>
                    <CardForm>
                        <h2>Dados do Pix</h2>
                        <p>* Campos Obrigatórios</p>
                        <br />
                        <form id="formPlaca">

                            <InputContainer>
                                <label htmlFor="nome">Nome do Beneficiário *</label>
                                <input
                                    type="text"
                                    placeholder="Nome"
                                    id="nome"
                                    value={nome}
                                    onChange={handleNomeChange}
                                    required
                                />
                            </InputContainer>


                            <InputContainer>
                                <label htmlFor="tipo-chave">Tipo de chave *</label>
                                <select id="tipo-chave" name="tipo-chave" placeholder="Selecione o tipo" defaultValue="" onChange={handleTipoChaveChange} required>
                                    <option value="" disabled >Selecione ...</option>
                                    <option value="CPF">CPF</option>
                                    <option value="CNPJ">CNPJ</option>
                                    <option value="E-mail">E-mail</option>
                                    <option value="Celular">Celular</option>
                                </select>

                            </InputContainer>

                            {
                                tipoChave !== "" &&
                                <InputContainer>
                                    <label htmlFor="chavepix">Chave PIX *</label>
                                    {renderCampoChavePix()}
                                </InputContainer>

                            }
                            <InputContainer>
                                <label htmlFor="banco">Banco *</label>
                                <input
                                    type="text"
                                    placeholder="Banco"
                                    id="banco"
                                    value={banco}
                                    onChange={handleBancoChange}
                                    required
                                />
                            </InputContainer>
                            <InputContainer>
                                <label htmlFor="cidade">Cidade do Beneficiário *</label>
                                <input
                                    type="text"
                                    placeholder="Cidade"
                                    id="cidade"
                                    value={cidade}
                                    onChange={handleCidadeChange}
                                    required
                                />
                            </InputContainer>
                            <br />
                            <h2>Personalize sua placa:</h2>
                            <br />
                            <InputContainer>
                                <label htmlFor="theme">Layout da Placa *</label>
                                <select id="theme" name="theme" placeholder="Selecione o layout" defaultValue="" onChange={handleThemeChange} required>
                                    <option value="" disabled >Selecione ...</option>
                                    <option value="Theme1">Modelo 1 - Com QR Code</option>
                                    <option value="Theme2">Modelo 2 - Pix Copia e Cola</option>
                                    <option value="Theme3">Modelo 3 - Somente dados</option>
                                    <option value="Theme4">Modelo 4 - Com QR Code e Logo</option>
                                </select>

                            </InputContainer>
                            {
                                (theme === "Theme3" || theme === "Theme4") &&
                                <>
                                    <InputColorContainer>
                                        <label htmlFor="fileLogo">Logo</label>
                                        <input
                                            type="file"
                                            name="fileLogo"
                                            id="fileLogo"
                                            accept="image/jpg, image/jpeg, image/png"
                                            onChange={handleLogoUpload}
                                        />
                                    </InputColorContainer>
                                    {
                                        logoUrl !== "" &&
                                        <InputContainer>
                                            <label htmlFor="logoWidth">Tamanho da Logo</label>
                                            <input
                                                type="number"
                                                id="logoWidth"
                                                min={100}
                                                max={600}
                                                value={logoWidth}
                                                onChange={handleLogoWidthChange}
                                            />
                                        </InputContainer>
                                    }
                                </>
                            }

                            <InputColorContainer>
                                <label htmlFor="fileBackground">Imagem de fundo</label>
                                <input
                                    type="file"
                                    name="fileBackground"
                                    id="fileBackground"
                                    accept="image/jpeg, image/png"
                                    onChange={handleImgUpload}
                                />
                            </InputColorContainer>
                            <InputColorContainer>
                                <label htmlFor="colorBackground">Cor de Fundo</label>
                                <input
                                    type="color"
                                    id="colorBackground"
                                    value={colorBackground}
                                    onChange={handleColorBackgroundChange}
                                />
                            </InputColorContainer>
                            <InputColorContainer>
                                <label htmlFor="colorTitle">Cor do Título</label>
                                <input
                                    type="color"
                                    id="colorTitle"
                                    value={colorTitle}
                                    onChange={handleColorTitleChange}
                                />
                            </InputColorContainer>
                            <InputColorContainer>
                                <label htmlFor="colorFont">Cor da Letra</label>
                                <input
                                    type="color"
                                    id="colorFont"
                                    value={colorFont}
                                    onChange={handleColorFontChange}
                                />
                            </InputColorContainer>
                            <InputColorContainer>
                                <label htmlFor="colorTag">Cor da Tag</label>
                                <input
                                    type="color"
                                    id="colorTag"
                                    value={colorTag}
                                    onChange={handleColorTagChange}
                                />
                            </InputColorContainer>
                            <InputContainer>
                                <label htmlFor="borderType">Tipo de borda</label>
                                <select id="borderType" name="borderType" placeholder="Selecione o tipo" defaultValue="" onChange={handleBorderTypeChange}>
                                    <option value="" disabled >Selecione ...</option>
                                    <option value="none">Nenhuma</option>
                                    <option value="solid">Sólida</option>
                                    <option value="dashed">Tracejada</option>
                                    <option value="double">Dupla</option>
                                    <option value="dotted">Pontilhada</option>

                                </select>

                            </InputContainer>
                            <InputContainer>
                                <label htmlFor="borderWidth">Largura da Borda</label>
                                <input
                                    type="number"
                                    id="borderWidth"
                                    min={0}
                                    max={60}
                                    defaultValue={0}
                                    value={borderWidth}
                                    onChange={handleBorderWidthChange}
                                />
                            </InputContainer>

                            <InputColorContainer>
                                <label htmlFor="borderColor">Cor da Borda</label>
                                <input
                                    type="color"
                                    id="borderColor"
                                    value={borderColor}
                                    onChange={handleBorderColorChange}
                                />
                            </InputColorContainer>
                            <button onClick={handleCheckForm}>
                                💾 Fazer download</button>

                            {
                                (theme === "Theme3" && logoUrl !== "") &&
                                <button onClick={handleRemoverLogo}>
                                    🗑 Remover logo</button>
                            }


                        </form>
                    </CardForm>
                </ContainerCards>
            </Content>
        </Container>
    )
}