import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  
  :root {
    --text-color: #f0f2f5;
    --bg-url: url("bg-desktop.jpg");

    // variáveis de cores
    --purple: #6933FF;
    --bg-header: rgba(255, 255, 255, 0.2);
    --gray-100: rgba(41, 41, 46, 0.1);
    --gray-200: rgba(41, 41, 46, 0.2);
    --gray-300: rgba(41, 41, 46, 0.3);
    --gray-400: rgba(41, 41, 46, 0.4);
    --gray-500: rgba(41, 41, 46, 0.5);
    --gray-600: rgba(41, 41, 46, 0.6);
    --gray-700: rgba(41, 41, 46, 0.7);
    --gray-800: rgba(41, 41, 46, 0.8);
    --gray-900: rgba(41, 41, 46, 0.9);
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }


  // 1rem = font-size 
  html {
    @media (max-width: 1380px) {
      font-size: 93.75%; // 15px
    }

    @media (max-width: 720px){
      font-size: 87.5%; // 14px
    }
  }

  body {
    /* height: 100vh; */
    -webkit-font-smoothing: antialiased;
  }

  body * {
    font-family: "Poppins", sans-serif;
    color: var(--text-color);
  }

  button {
    cursor: pointer;
  }

/* 
  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  } */
  

`;