import { Assinatura, Container, Content, CourierPrime, Tutorial } from "./styles";

export function Instructions() {
    return (
        <Container>
            <Content>
                <h2>Bem-vindo ao Gerador de Placa Pix!</h2>

                <p>Certamente, você já passou pela experiência de efetuar um pagamento PIX em um estabelecimento, utilizando aquela plaquinha com um QR Code sobre o balcão.
                    Agora, imagine ter o poder de criar a sua própria versão personalizada dessa plaquinha, sem a necessidade de habilidades em editores de imagem, de forma gratuita e ilimitada.</p>

                <h4>Sobre</h4>
                <p>O Gerador de Placa Pix é um projeto open source que permite aos usuários criar a placa para pagamentos em pix e personalizar do seu jeito, de forma segura, simples e rápida. </p>

                <h4>Por trás do código</h4>
                <CourierPrime>Olá! Eu sou a Laura, Desenvolvedora Web Full Stack, seja bem-vindo(a) a este projeto que foi desenvolvido com muita dedicação e carinho! </CourierPrime>
                <CourierPrime>Este projeto é um reflexo da minha paixão pela tecnologia e pela busca constante por soluções práticas, por isso convido você a explorar, criar e, claro, compartilhar suas ideias comigo.
                    A seção de contato está logo abaixo do gerador. Estou aqui para ouvir sugestões, colaborações, tirar dúvidas, ou simplesmente para um bate-papo.  </CourierPrime>
                <CourierPrime>Antes de começar sua jornada de criação, confira as informações abaixo. Elas são essenciais para você aproveitar ao máximo esta ferramenta incrível.</CourierPrime>

                <CourierPrime>Obrigada pela visita e volte sempre!</CourierPrime>

                <Assinatura>Laura La-Rocca Ferreira</Assinatura>


                <h2>TERMOS E CONDIÇÕES</h2>

                <h4>Segurança</h4>
                <p>- Esta ferramenta não armazena seus dados.</p>

                <h4>Teste de Leitura do QR Code</h4>
                <p>- Antes de salvar o arquivo para impressão, recomendamos que você realize um teste de leitura do QR Code. Isso garantirá que os dados estejam corretos e legíveis.</p>

                <h4>Responsabilidade do Usuário</h4>
                <p>- É importante ressaltar que não assumimos responsabilidade por quaisquer dados incorretos inseridos pelo usuário.</p>

                <h4>QrCode Estático</h4>
                <p>- Esclarecemos que a geração do QR Code Estático é realizada de forma independente, sem qualquer conexão direta com o sistema PIX ou instituições bancárias.
                    A função principal é fornecer uma representação visual dos dados PIX em formato QR Code.</p>

                <h4>Gratuito</h4>
                <p>- Como um projeto de código aberto, esta ferramenta é oferecida de forma completamente gratuita, sem qualquer custo associado à geração da placa.</p>

                <Tutorial>
                    <h2>COMO USAR A FERRAMENTA</h2>

                    <p>O Gerador de Placa Pix é projetado para se adaptar ao seu estilo de vida. Você pode criar suas placas PIX facilmente tanto no seu celular quanto no computador.
                        No entanto, para uma experiência mais completa, sugerimos o uso no computador, onde a personalização se torna ainda mais intuitiva. Experimente a versatilidade, crie suas placas PIX onde for mais conveniente para você!</p>

                    <h4>🖊 Preencha o Formulário</h4>
                    <p>- Comece preenchendo o formulário com as informações que você deseja que apareçam na placa. A placa só aparecerá em tela após a escolha do layout.</p>

                    <h4>💡 Escolha o layout</h4>
                    <p>- Selecione o modelo da placa que você deseja criar. O Modelo 1 tem um QR Code para pagamentos rápidos, o Modelo 2 oferece Pix Copia e Cola para praticidade,
                        o Modelo 3 é perfeito para enviar ao cliente, permitindo personalização com logo e ajuste de tamanho, e o Modelo 4 exibe o QR Code, a logo e a chave Pix. Escolha e destaque-se!</p>

                    <h4>🌈 Personalize as Cores</h4>
                    <p>- A ferramenta oferece a flexibilidade de personalizar a cor de fundo, a cor da letra do título e da descrição, bem como a cor da tag.
                        Caso não queira optar por uma cor sólida de fundo, é possível inserir uma imagem.</p>

                    <h4>✨ Adicione Detalhes Com Bordas</h4>
                    <p>- Você também pode aprimorar o visual da placa adicionando uma borda. Escolha o tipo de borda, ajuste sua espessura e defina a cor que melhor se adequa ao seu design.</p>

                    <h4>💾 Download</h4>
                    <p>- Após criar o layout desejado, clique no botão "Fazer Download" para baixar a imagem no formato PNG. </p>

                    <h4>🎉 Pronto, sua placa personalizada estará pronta para uso.</h4>
                </Tutorial>

            </Content>
        </Container>
    )
}